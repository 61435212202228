import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikConsumer, FormikActions } from 'formik';
import {
  ModalView,
  Stack,
  Button,
  Text,
  withTranslation,
} from '@piwikpro/ui-components';

type ModalTypes = 'twoOptions' | 'threeOptions';

interface RouterPromptProps {
  type: ModalTypes
  onSave: FormikActions<any>['submitForm']
  onDiscard: FormikActions<any>['resetForm']
  isModified: boolean
  promptTriggered: boolean
  updatePromptStatus: (status: boolean) => void
  forceDisableNativePrompt: boolean
  t: (key: string, variables?: any) => string
}

const RouterPrompt: React.FunctionComponent<RouterPromptProps> = ({
  isModified,
  onDiscard,
  onSave,
  type,
  promptTriggered,
  updatePromptStatus,
  forceDisableNativePrompt,
  t,
}) => {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (!isModified && promptTriggered) {
      onDiscard();
    }

    if (isModified) {
      if (!forceDisableNativePrompt) {
        window.onbeforeunload = () => window.confirm('Navigate Back?');
      }
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      updatePromptStatus(false);
      history.block(() => { });
    }

    return () => {
      history.block(() => { });
      window.onbeforeunload = () => { };
    };
  }, [history, isModified, promptTriggered]);

  useEffect(() => {
    if (forceDisableNativePrompt) {
      window.onbeforeunload = () => {};
    }
  }, [forceDisableNativePrompt]);

  const handleKeepEditing = () => {
    setShowPrompt(false);
    updatePromptStatus(false);
  };

  const handleDiscard = async () => {
    history.block(() => { });
    if (currentPath) {
      history.push(currentPath);
    }

    await onDiscard();
    setShowPrompt(false);
    updatePromptStatus(false);
  };

  const handleSave = async () => {
    await onSave();
    history.block(() => { });

    if (currentPath) {
      history.push(currentPath);
    }

    setShowPrompt(false);
    updatePromptStatus(false);
  };

  if (isModified && (showPrompt || promptTriggered)) {
    if (type === 'twoOptions') {
      return (
        <ModalView
          title={t('form.unsavedChangesTracker.twoOptions.modal.title')}
          actions={(
            <Stack spacing="narrow" horizontalAlignment="right" verticalAlignment="center">
              <Button text={t('form.unsavedChangesTracker.twoOptions.modal.keep-editing')} onClick={handleKeepEditing} />
              <Button appearance="destructive" text={t('form.unsavedChangesTracker.twoOptions.modal.discard')} onClick={handleDiscard} />
            </Stack>
          )}
        >
          <Text>
            {t('form.unsavedChangesTracker.twoOptions.modal.body')}
          </Text>
        </ModalView>
      );
    }

    return (
      <ModalView
        title={t('form.unsavedChangesTracker.threeOptions.modal.title')}
        actions={(
          <Stack spacing="narrow" horizontalAlignment="right" verticalAlignment="center">
            <Stack.Item fill>
              <Button text={t('form.unsavedChangesTracker.threeOptions.modal.dont-save')} onClick={handleDiscard} />
            </Stack.Item>
            <Stack.Item>
              <Stack spacing="narrow">
                <Stack.Item>
                  <Button text={t('form.unsavedChangesTracker.threeOptions.modal.keep-editing')} onClick={handleKeepEditing} />
                </Stack.Item>
                <Stack.Item>
                  <Button appearance="primary" text={t('form.unsavedChangesTracker.threeOptions.modal.save')} onClick={handleSave} />
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        )}
      >
        <Text>
          {t('form.unsavedChangesTracker.threeOptions.modal.body')}
        </Text>
      </ModalView>
    );
  }

  return null;
};

interface UnsavedChangesTrackerProps {
  type: ModalTypes
  render: (api: {
    UnsavedChangesModal: React.ElementType
    onClose: () => void
    forceDisableNativePrompt: () => void
  }) => React.ReactElement
  discardAction?: () => void
  onSave?: () => void
  isModified?: boolean
  t: (key: string, variables?: any) => string
}

const UnsavedChangesTracker = ({
  render, type, discardAction, onSave, isModified, t,
}: UnsavedChangesTrackerProps) => {
  const [promptTriggered, setPromptTriggered] = useState(false);
  const [forceDisabledNativePrompt, setForceDisabledNativePrompt] = useState(false);

  const formikApi = {
    onDiscard: api => api.resetForm,
    onSave: api => api.submitForm,
  };

  const UnsavedChangesModal = () => (
    <FormikConsumer>
      {(formik) => {
        const isFormModified = formik.dirty && !formik.isSubmitting;

        return (
          <RouterPrompt
            t={t}
            promptTriggered={promptTriggered}
            updatePromptStatus={setPromptTriggered}
            type={type}
            forceDisableNativePrompt={forceDisabledNativePrompt}
            isModified={isModified !== undefined ? isModified : isFormModified}
            onDiscard={discardAction || formikApi.onDiscard(formik)}
            onSave={onSave || formikApi.onSave(formik)}
          />
        );
      }}
    </FormikConsumer>
  );

  return render({
    UnsavedChangesModal,
    onClose: () => setPromptTriggered(true),
    forceDisableNativePrompt: () => setForceDisabledNativePrompt(true),
  });
};
export default withTranslation('platform')(UnsavedChangesTracker);
