import React from 'react';
import { WithTranslation } from 'react-i18next';
import {
  ModalView,
  withTranslation,
  Stack,
  Button,
  ButtonAppearance,
} from '@piwikpro/ui-components';
import { withInject } from '@piwikpro/platform';
import { Confirmation } from '../../Confirmation';

export interface CustomConfirmProps {
  name?: string
  title?: string
  applyBtnText?: string
  applyBtnAppearance?: ButtonAppearance
  onCancel?: () => void
  onSubmit?: (event: React.SyntheticEvent) => void
  t: (key: string, variables?: any) => string
  confirmation: Confirmation
}

export class CustomConfirm extends React.Component<CustomConfirmProps> {
  render() {
    const {
      name,
      title,
      applyBtnText,
      applyBtnAppearance,
      onSubmit,
      onCancel,
      t,
      confirmation,
    }: CustomConfirmProps = this.props;
    let body: React.ReactNode = null;
    const customBody = confirmation.getCustomBody();
    if (customBody) {
      body = React.createElement(customBody);
    }

    return (
      <ModalView
        title={t(title || '', { name })}
        actions={(
          <Stack spacing="narrow" horizontalAlignment="right" verticalAlignment="center">
            <Button
              type="button"
              text={t('components:cancel')}
              appearance="default"
              onClick={onCancel}
            />
            <Button
              type="submit"
              text={applyBtnText ? t(applyBtnText) : t('profile:deleteConfirm.modal.submit')}
              appearance={applyBtnAppearance || 'destructive'}
              onClick={onSubmit}
              focused
            />
          </Stack>
        )}
        onClose={onCancel}
      >
        <form onSubmit={onSubmit}>
          {body}
        </form>
      </ModalView>
    );
  }
}
export default withInject({
  confirmation: 'ConfirmationCrate.confirmation',
})(withTranslation(['profile', 'components'])(CustomConfirm));
