import { Subject, Observable } from 'rxjs';
import { AnyAction } from 'redux';
import { Location, Action } from 'history';
import { boundMethod } from 'autobind-decorator';

interface window {
  dataLayer?: any[]
  location?: Location
}

// @ts-ignore:next-line
const win: window = window;

export interface ActionEvent {
  type: string
  payload?: any
}

export class ProductAnalytics {
  private readonly stream = new Subject<ActionEvent>();

  @boundMethod
  createReduxMiddleware() {
    return () => (
      next: Function,
    ) => (
      action: AnyAction,
    ) => {
      next(action);
      this.stream.next(action);
    };
  }

  @boundMethod
  createHistoryListener() {
    return (location: Location, action: Action) => {
      this.stream.next({
        type: '@@router/LOCATION_CHANGE',
        payload: {
          location,
          action,
        },
      });
    };
  }

  push(event: ActionEvent) {
    this.stream.next(event);
  }

  setUserContext(email: string) {
    win.dataLayer = win.dataLayer || [];

    win.dataLayer.push({
      email,
    });
  }


  getStream(): Observable<ActionEvent> {
    return this.stream.asObservable();
  }
}
